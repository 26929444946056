import gql from 'graphql-tag'

export const VueBlockUpdateFragment = gql`
  fragment VueBlockUpdate on VueBlock {
    name
    title
    roles
    template
    script
    style
    dataScript
    autoUpdateData
    injectNewData
    apiCalls {
      name
      title
      script
    }
  }
`

export const VueBlockCreateFragment = gql`
  fragment VueBlockCreate on VueBlock {
    _id
    environmentId
    createdAt
  }
`

export const VueBlockFragment = gql`
  fragment VueBlock on VueBlock {
    ...VueBlockCreate
    ...VueBlockUpdate
  }

  ${VueBlockCreateFragment}
  ${VueBlockUpdateFragment}
`
